import Vue from "vue";

import moment from "moment";

import { TASK_FREQUENCY } from "@/shared/constants";

export function updateTask(payload) {
  return new Promise(function(resolve, reject) {
    let _this = payload.this;

    let taskIdx = _this.dataTable.dataSet.findIndex(
      item => item.ID === payload.id
    );
    let task = _this.dataTable.dataSet[taskIdx];

    if (task === undefined) {
      _this.$form.makeToastInfo(`Task ${payload.id} undefined`);
      return;
    }

    let data = {
      id: payload.id,
      date_start: payload.start,
      due_date: payload.end
    };

    //update client's dataset
    task["Start Date"] = data.date_start;

    task["Due Date"] = data.due_date;

    Vue.set(_this.dataTable.dataSet, taskIdx, task);

    _this.$api
      .put(`tasks/${task.ID}`, data)
      .then(() => {
        Vue.set(_this.dataTable.dataSet, taskIdx, task);

        _this.$form.makeToastInfo(`Task ${task.ID} updated`);

        resolve(true);

        //self.drawDataTable();
      })
      .catch(response => {
        Vue.set(_this.dataTable.dataSet, taskIdx, task);

        console.log(response);

        _this.$form.makeToastError(response.message);

        reject(response);
        //self.drawDataTable();
      });
  });
}

export function completeTask(id, _this) {
  let taskIdx = _this.dataTable.dataSet.findIndex(item => item.ID === id);

  let task = _this.dataTable.dataSet[taskIdx];

  let data = {
    id: id,
    status: {
      id: "Completed",
      label: "Completed"
    }
  };

  task["Status"] = "Completed";

  task.isCompleting = true;

  Vue.set(_this.dataTable.dataSet, taskIdx, task);
  console.log(data);
  _this.$api
    .put(`tasks/${task.ID}`, data)
    .then(() => {
      task.isCompleting = false;

      Vue.set(_this.dataTable.dataSet, taskIdx, task);

      //send event to the parent component 'Tasks' which will apply custom filters and hide completed task.
      _this.$emit("loaded");

      _this.$form.makeToastInfo("Task completed");
    })
    .catch(response => {
      task.isCompleting = false;

      Vue.set(_this.dataTable.dataSet, taskIdx, task);

      console.log(response);

      _this.$form.makeToastError(response.data.message);

      //self.drawDataTable();
    });
}

export function completeTasks(tasks_id, _this) {
  _this.isLoading = true;

  _this.$api
    .put(`tasks/bulk/complete`, tasks_id)
    .then(() => {
      _this.isLoading = false;

      //send event to the parent component 'Tasks' which will apply custom filters and hide completed task.
      _this.$emit("loaded");

      _this.getData();

      _this.$form.makeToastInfo("Tasks completed");
    })
    .catch(response => {
      _this.isLoading = false;

      _this.$form.makeToastError(response.data.message);
    });
}
export async function completeActivityTask(id, _this) {
  let data = {
    id: id,
    status: {
      id: "Completed",
      label: "Completed"
    }
  };

  return _this.$api.put(`tasks/${id}`, data);
}

export function submitActivity(id, _this) {
  let taskIdx = _this.dataTable.dataSet.findIndex(item => item.ID === id);

  let task = _this.dataTable.dataSet[taskIdx];

  task.action.params.task_id = id;

  _this.$router.push({
    name: "Activity submission",
    params: {
      action: "create",
      params: task.action.params
    }
  });
}

export function cloneTask(id, _this, refreshDataset) {
  let taskIdx = _this.dataTable.dataSet.findIndex(item => item.ID === id);

  let task = _this.dataTable.dataSet[taskIdx];

  let self = _this;

  let data = { id: id };

  task.isCloning = true;

  Vue.set(_this.dataTable.dataSet, taskIdx, task);

  _this.$api
    .post(`tasks/${id}/clone`, data)
    .then(() => {
      task.isCloning = false;

      Vue.set(_this.dataTable.dataSet, taskIdx, task);

      //send event to the parent component 'Tasks' which will apply custom filters and hide completed task.
      _this.$emit("loaded");

      _this.$form.makeToastInfo("Task cloned");

      if (refreshDataset) self.drawDataTable();
    })
    .catch(response => {
      task.isCloning = false;

      Vue.set(_this.dataTable.dataSet, taskIdx, task);

      console.log(response);

      _this.$form.makeToastError(response.message);

      if (refreshDataset) self.drawDataTable();
    });
}

export function emailTask(id, _this, refreshDataset) {
  let taskIdx = _this.dataTable.dataSet.findIndex(item => item.ID === id);

  let task = _this.dataTable.dataSet[taskIdx];

  let self = _this;

  let data = {
    id: id
  };

  task.isEmailing = true;

  Vue.set(_this.dataTable.dataSet, taskIdx, task);

  _this.$api
    .post(`tasks/${id}/email`, data)
    .then(() => {
      task.isEmailing = false;

      Vue.set(_this.dataTable.dataSet, taskIdx, task);

      _this.$form.makeToastInfo("Email sent");
    })
    .catch(response => {
      task.isEmailing = false;

      Vue.set(_this.dataTable.dataSet, taskIdx, task);

      console.log(response);

      _this.$form.makeToastError(response.message);

      if (refreshDataset) self.drawDataTable();
    });
}

export function postponeTask(id, _this) {
  let taskIdx = _this.dataTable.dataSet.findIndex(item => item.ID === id);

  let task = _this.dataTable.dataSet[taskIdx];

  let frequency = task["Recurring"];

  let { startDateNew, dueDateNew } = getPostponedDates(
    task["Start Date"],
    task["Due Date"],
    frequency
  );

  let self = _this;

  let data = {
    id: id,
    date_start: startDateNew.format("YYYY-MM-DD"),
    due_date: dueDateNew.format("YYYY-MM-DD"),
    recurringtype: {
      id: frequency,
      label: frequency
    },
    status: {
      id: "Not Started",
      label: "Not Started"
    }
  };

  //update client's dataset
  task["Status"] = "Not Started";
  task["Start Date"] = data.date_start;
  task["Due Date"] = data.due_date;

  //task['Recurring'] = data.recurringtype

  task.isPostponing = true;
  Vue.set(_this.dataTable.dataSet, taskIdx, task);

  self.$api
    .put(`tasks/${task.ID}`, data)
    .then(() => {
      task.isPostponing = false;

      Vue.set(_this.dataTable.dataSet, taskIdx, task);

      self.$form.makeToastInfo("Task postponed");

      //self.drawDataTable();
    })
    .catch(response => {
      task.isPostponing = false;

      Vue.set(_this.dataTable.dataSet, taskIdx, task);

      console.log(response);

      self.$form.makeToastError(response.message);

      //self.drawDataTable();
    });
}

export function getPostponedDates(d1, d2, frequency) {
  if (frequency === TASK_FREQUENCY.DAILY) {
    d1 = moment.utc(d1).add(1, "day");
    d2 = moment.utc(d2).add(1, "day");
  }
  if (frequency === TASK_FREQUENCY.WEEKLY) {
    d1 = moment.utc(d1).add(1, "week");
    d2 = moment.utc(d2).add(1, "week");
  }
  if (frequency === TASK_FREQUENCY.BIWEEKLY) {
    d1 = moment.utc(d1).add(2, "week");
    d2 = moment.utc(d2).add(2, "week");
  }
  if (frequency === TASK_FREQUENCY.MONTHLY) {
    d1 = moment.utc(d1).add(1, "month");
    d2 = moment.utc(d2).add(1, "month");
  }
  if (frequency === TASK_FREQUENCY.QUARTERLY) {
    d1 = moment.utc(d1).add(1, "quarter");
    d2 = moment.utc(d2).add(1, "quarter");
  }
  if (frequency === TASK_FREQUENCY.YEARLY) {
    d1 = moment.utc(d1).add(1, "year");
    d2 = moment.utc(d2).add(1, "year");
  }

  return { startDateNew: d1, dueDateNew: d2 };
}
