var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('table-custom', {
    ref: "dataTable",
    attrs: {
      "name": "".concat(_vm.$customTable.getCustomTableName(_vm.module || _vm.$route.meta.module)),
      "loading": _vm.dataTable.isLoading,
      "data": _vm.dataTable.dataSet,
      "options": _vm.dataTable.options
    },
    on: {
      "row-select": _vm.onRowSelect,
      "inline-update": _vm.onInlineUpdate
    },
    scopedSlots: _vm._u([{
      key: "Status",
      fn: function fn(props) {
        return _c('div', {}, [props.row && !props.row['Action'] ? _c('b-dropdown', {
          attrs: {
            "variant": _vm.getTaskStatusColor(props.row.Status),
            "size": "sm",
            "right": "",
            "text": props.row.Status
          }
        }, _vm._l(_vm.getAvailableTaskStatuses(props.row.Status), function (status, index) {
          return _c('b-dropdown-item', {
            key: "ddi-".concat(index),
            on: {
              "click": function click($event) {
                return _vm.changeTaskStatus(props.row.ID, status.name);
              }
            }
          }, [_vm._v(" " + _vm._s(status.name) + " ")]);
        }), 1) : _vm._e()], 1);
      }
    }, {
      key: "custom-actions",
      fn: function fn(props) {
        return _c('div', {}, [_c('div', {
          staticClass: "btn-group"
        }, [_vm.profile.data.id === 165 ? _c('button', {
          staticClass: "btn btn-danger btn-sm",
          attrs: {
            "title": "Delete task"
          },
          on: {
            "click": function click($event) {
              return _vm.deleteItem(props.row.ID);
            }
          }
        }, [_c('font-awesome-icon', {
          attrs: {
            "icon": "ban"
          }
        })], 1) : _vm._e(), !props.row['Recurring'] && !props.row['Action'] ? _c('button', {
          staticClass: "btn btn-success btn-sm",
          attrs: {
            "title": "Complete task"
          },
          on: {
            "click": function click($event) {
              return _vm.completeTask(props.row.ID);
            }
          }
        }, [_c('clip-loader', {
          staticClass: "mt-1",
          attrs: {
            "size": 14,
            "color": "#36D7B7",
            "loading": props.row.isCompleting === true
          }
        }), !(props.row.isCompleting === true) ? _c('font-awesome-icon', {
          attrs: {
            "icon": "check-circle"
          }
        }) : _vm._e()], 1) : _vm._e(), !props.row['Recurring'] && props.row['Action'] ? _c('button', {
          staticClass: "btn btn-primary btn-sm",
          attrs: {
            "title": "Submit activity"
          },
          on: {
            "click": function click($event) {
              return _vm.submitActivity(props.row.ID);
            }
          }
        }, [_c('clip-loader', {
          staticClass: "mt-1",
          attrs: {
            "size": 14,
            "color": "#36D7B7",
            "loading": props.row.isCompleting === true
          }
        }), !(props.row.isCompleting === true) ? _c('font-awesome-icon', {
          staticClass: "ml-1",
          attrs: {
            "icon": "bolt"
          }
        }) : _vm._e()], 1) : _vm._e(), props.row['Recurring'] ? _c('button', {
          staticClass: "btn btn-secondary btn-sm",
          attrs: {
            "title": "Postpone task"
          },
          on: {
            "click": function click($event) {
              return _vm.postponeTask(props.row.ID);
            }
          }
        }, [_c('clip-loader', {
          staticClass: "mt-1",
          attrs: {
            "size": 14,
            "color": "#36D7B7",
            "loading": props.row.isPostponing === true
          }
        }), !(props.row.isPostponing === true) ? _c('font-awesome-icon', {
          attrs: {
            "icon": "clock"
          }
        }) : _vm._e()], 1) : _vm._e(), _c('button', {
          staticClass: "btn btn-info btn-sm",
          attrs: {
            "title": "Edit task"
          },
          on: {
            "click": function click($event) {
              return _vm.editItem(props.row.ID);
            }
          }
        }, [_c('font-awesome-icon', {
          attrs: {
            "icon": "pencil-alt"
          }
        })], 1), _c('button', {
          staticClass: "btn btn-primary btn-sm",
          attrs: {
            "title": "View task"
          },
          on: {
            "click": function click($event) {
              return _vm.viewItem(props.row.ID);
            }
          }
        }, [_c('font-awesome-icon', {
          attrs: {
            "icon": "eye"
          }
        })], 1), _c('button', {
          staticClass: "btn btn-info btn-sm",
          attrs: {
            "title": "Clone task"
          },
          on: {
            "click": function click($event) {
              return _vm.cloneTask(props.row.ID);
            }
          }
        }, [_c('clip-loader', {
          staticClass: "mt-1",
          attrs: {
            "size": 14,
            "color": "#36D7B7",
            "loading": props.row.isCloning === true
          }
        }), !(props.row.isCloning === true) ? _c('font-awesome-icon', {
          attrs: {
            "icon": "clone"
          }
        }) : _vm._e()], 1), _c('button', {
          staticClass: "btn btn-warning btn-sm",
          attrs: {
            "title": "Email task"
          },
          on: {
            "click": function click($event) {
              return _vm.emailTask(props.row.ID);
            }
          }
        }, [_c('clip-loader', {
          staticClass: "mt-1",
          attrs: {
            "size": 14,
            "color": "#36D7B7",
            "loading": props.row.isEmailing === true
          }
        }), !(props.row.isEmailing === true) ? _c('font-awesome-icon', {
          attrs: {
            "icon": "envelope"
          }
        }) : _vm._e()], 1), _c('button', {
          staticClass: "btn btn-dark btn-sm",
          attrs: {
            "title": "Preview task"
          },
          on: {
            "click": function click($event) {
              return _vm.showContentModal(props.row.ID);
            }
          }
        }, [_c('font-awesome-icon', {
          attrs: {
            "icon": "search"
          }
        })], 1), _c('button', {
          class: [props.row['Description'] ? 'btn btn-secondary btn-sm' : 'btn btn-outline-secondary btn-sm'],
          on: {
            "click": function click($event) {
              return _vm.toggleDescription(props.row['ID']);
            }
          }
        }, [!props.row['Description'] ? _c('font-awesome-icon', {
          attrs: {
            "icon": "file"
          }
        }) : _vm._e(), props.row['Description'] ? _c('font-awesome-icon', {
          attrs: {
            "icon": "file-alt"
          }
        }) : _vm._e()], 1), _c('button', {
          class: [props.row['FilesCount'] !== '0' ? 'btn btn-secondary btn-sm' : 'btn btn-outline-secondary btn-sm'],
          on: {
            "click": function click($event) {
              return _vm.toggleFiles(props.row['ID']);
            }
          }
        }, [_c('font-awesome-icon', {
          attrs: {
            "icon": "download"
          }
        })], 1)])]);
      }
    }, {
      key: "child_row",
      fn: function fn(props) {
        return _c('span', {}, [_vm.dataTable.childRow.showDescription ? _c('ckeditor', {
          ref: "ckeditor",
          attrs: {
            "editor": _vm.editor.mode,
            "config": _vm.editor.config
          },
          on: {
            "blur": function blur($event) {
              return _vm.onDescriptionBlur(props.row);
            }
          },
          model: {
            value: props.row['Description'],
            callback: function callback($$v) {
              _vm.$set(props.row, 'Description', $$v);
            },
            expression: "props.row['Description']"
          }
        }) : _vm._e(), _vm.dataTable.childRow.showFiles ? _c('files-container', {
          ref: "files",
          attrs: {
            "module-id": _vm.$route.meta.module.id,
            "entity-id": props.row['ID']
          }
        }) : _vm._e()], 1);
      }
    }])
  }, [_c('div', {
    attrs: {
      "slot": "afterFilter"
    },
    slot: "afterFilter"
  }, [_vm.isLoaded && _vm.isInsertAllowed && _vm.showAddButton ? _c('b-row', [_c('b-col', [_c('b-button-group', [_c('b-button', {
    attrs: {
      "variant": "outline-dark",
      "title": "Create task in new window"
    },
    on: {
      "click": function click($event) {
        return _vm.addTask('form');
      }
    }
  }, [_c('i', {
    staticClass: "fa fa-toolbar fa-plus"
  }), _vm._v(" Create ")]), _c('b-button', {
    attrs: {
      "variant": "outline-dark",
      "title": "Create task inline",
      "disabled": _vm.dataTable.isInserting
    },
    on: {
      "click": function click($event) {
        return _vm.addTask('inline');
      }
    }
  }, [_c('i', {
    staticClass: "fa fa-toolbar fa-plus-square"
  }), _vm._v(" Create inline ")]), _c('b-button', {
    attrs: {
      "variant": "outline-dark",
      "title": "Complete tasks",
      "size": "sm",
      "disabled": _vm.hasSelectedRows ? false : true
    },
    on: {
      "click": function click($event) {
        return _vm.completeTasks();
      }
    }
  }, [_c('font-awesome-icon', {
    attrs: {
      "icon": "check-circle"
    }
  }), _vm._v(" Complete ")], 1)], 1)], 1)], 1) : _vm._e()], 1)]), _c('b-modal', {
    ref: "preview-modal",
    attrs: {
      "centered": "",
      "hide-footer": "",
      "size": "lg",
      "title": _vm.previewModal.title
    }
  }, [_c('b-card', [_c('b-card-body', [_c('b-row', [_c('b-col', {
    attrs: {
      "lg": "3"
    }
  }, [_c('strong', [_vm._v("Task Name: ")]), _vm._v(" " + _vm._s(_vm.previewModal.taskName) + " ")])], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "lg": "3"
    }
  }, [_c('strong', [_vm._v("Date: ")]), _vm._v(" " + _vm._s(_vm.previewModal.startDate) + " ")]), _c('b-col', {
    attrs: {
      "lg": "3"
    }
  }, [_c('strong', [_vm._v("Date: ")]), _vm._v(" " + _vm._s(_vm.previewModal.dueDate) + " ")]), _c('b-col', {
    attrs: {
      "lg": "3"
    }
  }, [_c('strong', [_vm._v("Time start: ")]), _vm._v(" " + _vm._s(_vm.previewModal.timeStart) + " ")]), _c('b-col', {
    attrs: {
      "lg": "3"
    }
  }, [_c('strong', [_vm._v("Time end: ")]), _vm._v(" " + _vm._s(_vm.previewModal.timeEnd) + " ")])], 1), _c('b-row'), _c('b-row', [_c('b-col', [_c('strong', [_vm._v("Description: ")]), _c('b-card-body', [_c('div', {
    staticClass: "preview-report",
    domProps: {
      "innerHTML": _vm._s(_vm.previewModal.description)
    }
  })])], 1)], 1)], 1)], 1), _c('hr'), _c('div', {
    staticClass: "form-row d-flex justify-content-end"
  }, [_c('b-button', {
    staticClass: "m-1",
    attrs: {
      "variant": "outline-dark"
    },
    on: {
      "click": function click($event) {
        return _vm.closeModal();
      }
    }
  }, [_c('font-awesome-icon', {
    attrs: {
      "icon": "times"
    }
  }), _vm._v(" Close ")], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }